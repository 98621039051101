/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Enum that defines all possible filter types.
 */
export var StartsWithFilterType;
(function (StartsWithFilterType) {
    StartsWithFilterType["STARTS_WITH"] = "startsWith";
})(StartsWithFilterType || (StartsWithFilterType = {}));
