export const hasCreateProjectPermission = (userEntitlements) => {
    if (userEntitlements === undefined || userEntitlements?.dataPermissions === undefined) {
        return false;
    }
    else {
        return validatePartyNumber(userEntitlements?.primaryPartyType, userEntitlements?.dataPermissions?.energyFlowProjects?.view);
    }
};
export const validatePartyNumber = (partyNumber, permission) => {
    const partyFilter = permission?.filterConditions.filter((fc) => fc?.partyNumbers !== undefined);
    return partyFilter?.[0].partyNumbers.includes(partyNumber);
};
export const getUserPartyNumber = (userEntitlements) => {
    if (userEntitlements === undefined || userEntitlements?.dataPermissions === undefined) {
        return "";
    }
    else {
        const permission = userEntitlements?.dataPermissions?.energyFlowProjects?.view;
        const partyFilter = permission?.filterConditions.filter((fc) => fc?.partyNumbers !== undefined);
        return partyFilter?.[0]?.partyNumbers?.[0];
    }
};
