import axios from "axios";
import { baseUrls } from "./api.types";
import { errorInterceptor, requestInterceptor, successInterceptor } from "./interceptors";
const axiosRequestConfig = {
    baseURL: baseUrls.caasV1ApiDev.url,
    headers: {
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    },
    responseType: "json"
};
const entitlementsRequestConfig = {
    baseURL: baseUrls.entitlementsV1Api.url,
    headers: {
        "Content-Type": "application/json"
    },
    responseType: "json"
};
const appConfigRequest = {
    baseURL: "",
    headers: {
        "Content-Type": "application/json"
    },
    responseType: "json"
};
const api = axios.create(axiosRequestConfig);
const appConfigApi = axios.create(appConfigRequest);
const entitlementsApi = axios.create(entitlementsRequestConfig);
api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(successInterceptor, errorInterceptor);
entitlementsApi.interceptors.request.use(requestInterceptor);
entitlementsApi.interceptors.response.use(successInterceptor, errorInterceptor);
appConfigApi.interceptors.request.use(requestInterceptor);
appConfigApi.interceptors.response.use(successInterceptor, errorInterceptor);
export { api, appConfigApi, entitlementsApi };
