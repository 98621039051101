import { MockRouteMethods } from "../constants";
import { energyMetricsHandler } from "./factories/projectDetails";
export const projectDetailRoutes = [
    {
        baseURLs: ["https://api-int.cat.com/catDigital/caas/v1"],
        methods: [
            {
                handler: energyMetricsHandler,
                verb: MockRouteMethods.GET
            }
        ],
        path: "/projects/:projectId/energy/metrics"
    }
];
