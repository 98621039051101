/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Enum that defines all possible filter types.
 */
export var RangeFilterType;
(function (RangeFilterType) {
    RangeFilterType["TIME_RANGE"] = "timeRange";
})(RangeFilterType || (RangeFilterType = {}));
