/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Sort the results by the selected value.
 * Values should be unique and case-sensitive.
 * - This field must be present if and only if the orderBy field is present.
 * - It must contain the same number of items as `orderBy`.
 *
 */
export var locationAttributesortBy;
(function (locationAttributesortBy) {
    locationAttributesortBy["LAST_REPORTED_TIME"] = "lastReportedTime";
    locationAttributesortBy["STREET_ADDRESS"] = "streetAddress";
})(locationAttributesortBy || (locationAttributesortBy = {}));
