import { getAppEnv } from "../lib/getEnvVariables";
const loggerImpl = (f, name) => (set, get, store) => {
    const loggedSet = (...a) => {
        set(...a);
        // IF webpack is running in development
        if (getAppEnv() === "dev") {
            console.log(...(name ? [`${name}:`] : []), get());
        }
    };
    store.setState = loggedSet;
    return f(loggedSet, get, store);
};
export const logger = loggerImpl;
