export const EventSearchMockData = {
    assetChargeActivities: [
        {
            activity: "Self Discharge",
            activityEndTimeStamp: "2024-08-14T05:16:30",
            activityStartTimeStamp: "2024-08-14T05:14",
            assetId: "bbfe281c-dbd2-4939-b048-bf421d96663e",
            assetName: "433-330",
            assetSourceId: "05f2771a-c7ab-4da7-b797-c596f193a1f0",
            assetSourceType: "MACHINE",
            chargeEnergy: 0.0,
            dischargeEnergy: 0.3437970280647278,
            maxSOC: 86.0,
            minSOC: 86.0
        },
        {
            activity: "Working",
            activityEndTimeStamp: "2024-08-14T05:17:30",
            activityStartTimeStamp: "2024-08-14T05:17",
            assetId: "13bc13f6-6f05-4096-b058-81bd4550386c",
            assetName: "BQ6-380",
            assetSourceId: "05f2771a-c7ab-4da7-b797-c596f193a1f0",
            assetSourceType: "ESS",
            chargeEnergy: 0.0,
            dischargeEnergy: 1.20163094997406,
            maxSOC: 85.283935546875,
            minSOC: 85.78192901611328
        },
        {
            activity: "Working",
            activityEndTimeStamp: "2024-08-14T05:17:30",
            activityStartTimeStamp: "2024-08-14T05:17",
            assetId: "185dc18e-f465-45f9-8018-c75677ea1add",
            assetName: "RL3-Rental",
            assetSourceId: "05f2771a-c7ab-4da7-b797-c596f193a1f0",
            assetSourceType: "ESS",
            chargeEnergy: 0.0,
            dischargeEnergy: 1.20163094997406,
            maxSOC: 85.283935546875,
            minSOC: 85.78192901611328
        }
    ],
    projectId: "308a84bb-fc3a-442e-92c9-5508086385f0",
    projectName: "Hansweert"
};
