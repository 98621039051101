import { useMutation } from "@tanstack/react-query";
import { createNewProject } from "../api/create-project-service";
export const useCreateProjectMutate = (partyNumber) => {
    return useMutation({
        gcTime: 0,
        mutationFn: (createProjectRequest) => {
            return createNewProject(partyNumber, createProjectRequest);
        },
        retry: false
    });
};
