import { Response } from "miragejs";
import { HttpErrorCode } from "../../../../../utils/src/lib/constants/ErrorCodes";
import { HttpStatusCode } from "../../../../../utils/src/lib/constants/StatusCodes";
import { errorResponses } from "../../__mocks__/errors";
import { appConfigMockData } from "../__mocks__/appConfig.mock";
import { EnergyActivityMockData } from "../__mocks__/energyActivity.mock";
import { EnergyMetricsMockData } from "../__mocks__/energyMetrics";
import { EventSearchMockData } from "../__mocks__/eventSearch.mock";
import { usageHistoryESSHourlyData, usageHistoryHistogramDailyData, usageHistoryHistogramMonthlyData, usageHistoryHistogramWeeklyData, usageHistoryMachineHourlyData } from "../__mocks__/usageHistory.mock";
export function usageHistoryHistogramHandler(_schema, _request) {
    const params = _request.params || {};
    const queryParams = _request.queryParams || {};
    if (!params?.projectId || !queryParams?.startDate || !queryParams?.aggregation) {
        return new Response(HttpStatusCode.BAD_REQUEST, {}, errorResponses[HttpErrorCode.MISSING_REQFIELDS]);
    }
    if (queryParams?.aggregation === "weekly")
        return new Response(HttpStatusCode.OK, {}, usageHistoryHistogramWeeklyData);
    else if (queryParams?.aggregation === "daily")
        return new Response(HttpStatusCode.OK, {}, usageHistoryHistogramDailyData);
    else if (queryParams?.aggregation === "hourly")
        return queryParams?.assetSourceType === "ESS"
            ? new Response(HttpStatusCode.OK, {}, usageHistoryESSHourlyData)
            : new Response(HttpStatusCode.OK, {}, usageHistoryMachineHourlyData);
    else
        return new Response(HttpStatusCode.OK, {}, usageHistoryHistogramMonthlyData);
}
export function energyMetricsHandler(_schema, _request) {
    const params = _request.params || {};
    const queryParams = _request.queryParams || {};
    if (!params?.projectId || !queryParams?.startDate) {
        return new Response(HttpStatusCode.BAD_REQUEST, {}, errorResponses[HttpErrorCode.MISSING_REQFIELDS]);
    }
    const requestTypeValidation = typeof params?.projectId === "string" && typeof queryParams?.startDate === "string";
    if (!requestTypeValidation) {
        return new Response(HttpStatusCode.BAD_REQUEST, {}, errorResponses[HttpErrorCode.INVALID_REQUEST_BODY]);
    }
    return new Response(HttpStatusCode.OK, {}, EnergyMetricsMockData);
}
export function energyActivityHandler(_schema, _request) {
    const params = _request.params || {};
    const queryParams = _request.queryParams || {};
    if (!params?.projectId || !queryParams?.startDate) {
        return new Response(HttpStatusCode.BAD_REQUEST, {}, errorResponses[HttpErrorCode.MISSING_REQFIELDS]);
    }
    const requestTypeValidation = typeof params?.projectId === "string" && typeof queryParams?.startDate === "string";
    if (!requestTypeValidation) {
        return new Response(HttpStatusCode.BAD_REQUEST, {}, errorResponses[HttpErrorCode.INVALID_REQUEST_BODY]);
    }
    return new Response(HttpStatusCode.OK, {}, EnergyActivityMockData);
}
export function eventSearchHandler(_schema, _request) {
    const params = _request.params || {};
    const queryParams = _request.queryParams || {};
    if (!params?.projectId || !queryParams?.startDate) {
        return new Response(HttpStatusCode.BAD_REQUEST, {}, errorResponses[HttpErrorCode.MISSING_REQFIELDS]);
    }
    const requestTypeValidation = typeof params?.projectId === "string" && typeof queryParams?.startDate === "string";
    if (!requestTypeValidation) {
        return new Response(HttpStatusCode.BAD_REQUEST, {}, errorResponses[HttpErrorCode.INVALID_REQUEST_BODY]);
    }
    return new Response(HttpStatusCode.OK, {}, EventSearchMockData);
}
export function appConfigHandler() {
    return new Response(HttpStatusCode.OK, {}, appConfigMockData);
}
