import { httpStatus } from "../shared/constants";
export const requestInterceptor = (config) => {
    const token = localStorage.getItem("access_token")?.replace(/"/g, ""); // Get the B2C token here
    config.headers.set("Authorization", `Bearer ${token}`);
    return config;
};
export const successInterceptor = (response) => {
    return response;
};
export const errorInterceptor = async (error) => {
    if (error.response?.status === httpStatus.ERROR_401 || error.response?.status === httpStatus.ERROR_403) {
        return Promise.reject(error);
    }
    else {
        if (error.response) {
            const errorMessage = {
                data: error.response.data,
                status: error.response.status
            };
            // LOG HERE
            console.error(errorMessage);
        }
        else if (error.request) {
            // LOG HERE
            console.error(error.request);
        }
        else {
            // LOG HERE
            console.error("Error", error.message);
        }
        return Promise.reject(error);
    }
};
