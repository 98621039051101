export const EnergyActivityMockData = {
    assetEnergyActivities: [
        {
            activity: "Discharging",
            assetId: "54a41d8f-7294-4134-9fe4-f9dc2ae4c46b",
            assetName: "BQ2-380-0400-0002 / v Oord Hansweert-Goes",
            assetSourceId: "BQ2",
            assetSourceType: "ESS",
            dischargeAssetId: "Other Machine",
            dischargeAssetName: "Other Machine",
            dischargeEnergy: 99.7518727551
        },
        {
            activity: "Discharging",
            assetId: "9a4dac44-2c5a-4496-b081-09739897315b",
            assetName: "RO4-RECO 4",
            assetSourceId: "2242b47c-6e64-4f04-82de-0f52adf58391",
            assetSourceType: "ESS",
            dischargeAssetId: "62442050-c274-4557-aa9b-5f48fece92b9",
            dischargeAssetName: "320-1(192)",
            dischargeEnergy: 248.280026733
        },
        {
            activity: "Discharging",
            assetId: "4825d971-fa31-4c77-8972-07ba94e6f26b",
            assetName: "BQ8-380-0400-0008 / v Oord Geldermalsen-Tiel",
            assetSourceId: "BQ8",
            assetSourceType: "ESS",
            dischargeAssetId: "62442050-c274-4557-aa9b-5f48fece92b9",
            dischargeAssetName: "320-1(192)",
            dischargeEnergy: 84.972264803
        },
        {
            activity: "Discharging",
            assetId: "4825d971-fa31-4c77-8972-07ba94e6f26b",
            assetName: "BQ8-380-0400-0008 / v Oord Geldermalsen-Tiel",
            assetSourceId: "BQ8",
            assetSourceType: "ESS",
            dischargeAssetId: "62442050-c274-4557-aa9b-5f48fece92b9",
            dischargeAssetName: "320-1(192)",
            dischargeEnergy: 126.062928332
        },
        {
            activity: "Discharging",
            assetId: "831be4e3-689b-4682-9f00-bb6b93182cb9",
            assetName: "VO3-Van Oord 3",
            assetSourceId: "56a7d9e6-aded-4826-ba6d-3ed4cbcac023",
            assetSourceType: "ESS",
            dischargeAssetId: "d971033a-50aa-4bcc-b0a3-302ee9dc4904",
            dischargeAssetName: "330-3(464)",
            dischargeEnergy: 231.59177377
        },
        {
            activity: "Discharging",
            assetId: "4c135e65-302d-47c0-bf34-112762b1fb2b",
            assetName: "RL9-Rental 9",
            assetSourceId: "f4f828c6-b9a6-4e23-96ce-320fbde0d1b7",
            assetSourceType: "ESS",
            dischargeAssetId: "d971033a-50aa-4bcc-b0a3-302ee9dc4904",
            dischargeAssetName: "330-3(464)",
            dischargeEnergy: 158.91430497
        },
        {
            activity: "Discharging",
            assetId: "831be4e3-689b-4682-9f00-bb6b93182cb9",
            assetName: "VO3-Van Oord 3",
            assetSourceId: "56a7d9e6-aded-4826-ba6d-3ed4cbcac023",
            assetSourceType: "ESS",
            dischargeAssetId: "ba1f6a12-f7e3-44ce-a4bb-0ff39a55a0e2",
            dischargeAssetName: "330-4(482)",
            dischargeEnergy: 124.1356566
        },
        {
            activity: "Discharging",
            assetId: "43677056-82b2-4885-9edd-0b5c09cb0d02",
            assetName: "RL6-Rental 6",
            assetSourceId: "7317d97e-fb66-4f6b-ad91-831a922d1f07",
            assetSourceType: "ESS",
            dischargeAssetId: "ba1f6a12-f7e3-44ce-a4bb-0ff39a55a0e2",
            dischargeAssetName: "330-4(482)",
            dischargeEnergy: 306.62495468
        },
        {
            activity: "Discharging",
            assetId: "62442050-c274-4557-aa9b-5f48fece92b9",
            assetName: "320-1(192)",
            assetSourceId: "192",
            assetSourceType: "MACHINE",
            dischargeAssetName: "Charging",
            dischargeEnergy: 22.7106795476
        },
        {
            activity: "Discharging",
            assetId: "62442050-c274-4557-aa9b-5f48fece92b9",
            assetName: "320-1(192)",
            assetSourceId: "192",
            assetSourceType: "MACHINE",
            dischargeAssetName: "No Activity logged",
            dischargeEnergy: 400.074098256
        },
        {
            activity: "Discharging",
            assetId: "d971033a-50aa-4bcc-b0a3-302ee9dc4904",
            assetName: "330-3(464)",
            assetSourceId: "464",
            assetSourceType: "MACHINE",
            dischargeAssetName: "Charging",
            dischargeEnergy: 21.9574691897
        },
        {
            activity: "Discharging",
            assetId: "d971033a-50aa-4bcc-b0a3-302ee9dc4904",
            assetName: "330-3(464)",
            assetSourceId: "464",
            assetSourceType: "MACHINE",
            dischargeAssetName: "Supplying material to bulldozer",
            dischargeEnergy: 348.54104423566
        },
        {
            activity: "Discharging",
            assetId: "ba1f6a12-f7e3-44ce-a4bb-0ff39a55a0e2",
            assetName: "330-4(482)",
            assetSourceId: "482",
            assetSourceType: "MACHINE",
            dischargeAssetName: "Charging",
            dischargeEnergy: 23.6383564997
        },
        {
            activity: "Discharging",
            assetId: "ba1f6a12-f7e3-44ce-a4bb-0ff39a55a0e2",
            assetName: "330-4(482)",
            assetSourceId: "482",
            assetSourceType: "MACHINE",
            dischargeAssetName: "Excavating and loading|Charging battery overnight",
            dischargeEnergy: 413.270743704
        },
        {
            activity: "Discharging",
            assetName: "WATTHUB",
            dischargeAssetId: "2242b47c-6e64-4f04-82de-0f52adf58391",
            dischargeAssetName: "RO4-RECO 4",
            dischargeEnergy: 327.65347570800003
        },
        {
            activity: "Discharging",
            assetName: "WATTHUB",
            dischargeAssetId: "56a7d9e6-aded-4826-ba6d-3ed4cbcac023",
            dischargeAssetName: "VO3-Van Oord 3",
            dischargeEnergy: 505.63508934424897
        },
        {
            activity: "Discharging",
            assetName: "WATTHUB",
            dischargeAssetId: "7317d97e-fb66-4f6b-ad91-831a922d1f07",
            dischargeAssetName: "RL6-Rental 6",
            dischargeEnergy: 26.087712136
        },
        {
            activity: "Discharging",
            assetName: "WATTHUB",
            dischargeAssetId: "BQ2",
            dischargeAssetName: "BQ2-380-0400-0002 / v Oord Hansweert-Goes",
            dischargeEnergy: 289.16500574679
        },
        {
            activity: "Discharging",
            assetName: "WATTHUB",
            dischargeAssetId: "BQ8",
            dischargeAssetName: "BQ8-380-0400-0008 / v Oord Geldermalsen-Tiel",
            dischargeEnergy: 280.084891225455
        },
        {
            activity: "Discharging",
            assetName: "WATTHUB",
            dischargeAssetId: "CQM1",
            dischargeAssetName: "CQM1",
            dischargeEnergy: 0.5725094442499999
        },
        {
            activity: "Discharging",
            assetName: "WATTHUB",
            dischargeAssetId: "f4f828c6-b9a6-4e23-96ce-320fbde0d1b7",
            dischargeAssetName: "RL9-Rental 9",
            dischargeEnergy: 400.66588056
        },
        {
            activity: "NoActivityLogged",
            assetId: "9a4dac44-2c5a-4496-b081-09739897315b",
            assetName: "RO4-RECO 4",
            assetSourceId: "2242b47c-6e64-4f04-82de-0f52adf58391",
            assetSourceType: "ESS",
            dischargeAssetName: "Residual Energy",
            dischargeEnergy: 79.37344897500003
        },
        {
            activity: "NoActivityLogged",
            assetId: "831be4e3-689b-4682-9f00-bb6b93182cb9",
            assetName: "VO3-Van Oord 3",
            assetSourceId: "56a7d9e6-aded-4826-ba6d-3ed4cbcac023",
            assetSourceType: "ESS",
            dischargeAssetName: "Residual Energy",
            dischargeEnergy: 149.907658974249
        },
        {
            activity: "NoActivityLogged",
            assetId: "54a41d8f-7294-4134-9fe4-f9dc2ae4c46b",
            assetName: "BQ2-380-0400-0002 / v Oord Hansweert-Goes",
            assetSourceId: "BQ2",
            assetSourceType: "ESS",
            dischargeAssetName: "Residual Energy",
            dischargeEnergy: 189.41313299168996
        },
        {
            activity: "NoActivityLogged",
            assetId: "4825d971-fa31-4c77-8972-07ba94e6f26b",
            assetName: "BQ8-380-0400-0008 / v Oord Geldermalsen-Tiel",
            assetSourceId: "BQ8",
            assetSourceType: "ESS",
            dischargeAssetName: "Residual Energy",
            dischargeEnergy: 69.04969809045502
        },
        {
            activity: "NoActivityLogged",
            assetId: "4c135e65-302d-47c0-bf34-112762b1fb2b",
            assetName: "RL9-Rental 9",
            assetSourceId: "f4f828c6-b9a6-4e23-96ce-320fbde0d1b7",
            assetSourceType: "ESS",
            dischargeAssetName: "Residual Energy",
            dischargeEnergy: 241.75157559000002
        }
    ],
    projectId: "b51cfaa2-5755-4234-8548-f415a3d63a11",
    projectName: "Tiwa Dyke"
};
