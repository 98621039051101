/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Last Reported Time of the asset.
 *
 */
export var LastReportedTime;
(function (LastReportedTime) {
    LastReportedTime["TIMESTAMP"] = "Timestamp";
})(LastReportedTime || (LastReportedTime = {}));
