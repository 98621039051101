/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Asset Type that is used for filtering.
 *
 */
export var AssetTypePropertyForFiltering;
(function (AssetTypePropertyForFiltering) {
    AssetTypePropertyForFiltering["ASSET_SOURCE_TYPE"] = "assetSourceType";
    AssetTypePropertyForFiltering["ASSET_ID"] = "assetId";
})(AssetTypePropertyForFiltering || (AssetTypePropertyForFiltering = {}));
