import { create } from "zustand";
import { logger } from "./logger";
const initialState = {
    projectAddress: null,
    projectId: null,
    projectName: null,
    projectOwner: null
};
const useCreateNewProjectStore = create()(logger((set) => ({
    ...initialState,
    setProjectAddress: (projectAddress) => {
        set(() => ({ projectAddress }));
    },
    setProjectId: (projectId) => {
        set(() => ({ projectId }));
    },
    setProjectName: (projectName) => {
        set(() => ({ projectName }));
    },
    setProjectOwner: (projectOwner) => {
        set(() => ({ projectOwner }));
    }
}), "createNewProjectStore"));
export default useCreateNewProjectStore;
