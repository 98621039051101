export const EnergyMetricsMockData = {
    energyMetrics: [
        {
            essEnergy: {
                currentDay: 723.0267,
                lastDay: {
                    energyTrend: 13.613,
                    value: 918.943
                },
                lastMonth: {
                    energyTrend: 598.002,
                    value: 57389.2
                },
                lastWeek: {
                    energyTrend: -72.104,
                    value: 5730.86
                }
            },
            machineEnergy: {
                currentDay: 7448.853,
                lastDay: {
                    energyTrend: null,
                    value: null
                },
                lastMonth: {
                    energyTrend: 2187.682,
                    value: 581714.0
                },
                lastWeek: {
                    energyTrend: 93.226,
                    value: 72021.3
                }
            },
            residualEnergy: {
                currentDay: -6725.8263,
                lastDay: {
                    energyTrend: -35.908,
                    value: -699.537
                },
                lastMonth: {
                    energyTrend: 2947.303,
                    value: -524325.0
                },
                lastWeek: {
                    energyTrend: 296.253,
                    value: -66290.4
                }
            }
        }
    ],
    projectId: "b51cfaa2-5755-4234-8548-f415a3d63a11",
    projectName: "Tiwa Dyke"
};
