/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Project Properties that are used for filtering.
 *
 */
export var ProjectDateRangeFiltering;
(function (ProjectDateRangeFiltering) {
    ProjectDateRangeFiltering["START_DATE"] = "startDate";
    ProjectDateRangeFiltering["END_DATE"] = "endDate";
})(ProjectDateRangeFiltering || (ProjectDateRangeFiltering = {}));
