import { useQuery } from "@tanstack/react-query";
import { getAppConfigData } from "../api/project-config.service";
export const useAppConfigQuery = () => {
    return useQuery({
        queryFn: () => {
            return getAppConfigData();
        },
        queryKey: ["appConfig"],
        retry: 1
    });
};
