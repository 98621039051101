// Global error mocks
export const errorResponses = {
    "400": {
        code: "400.001",
        description: "Unknown error occurred.",
        status: 400
    },
    "401": {
        code: "401.006",
        description: "Missing Authorization header or token value.",
        status: 401
    },
    "403": {
        code: "403.001",
        description: "Forbidden",
        status: 403
    },
    "404": {
        code: "404.001",
        description: "Resource Not Found",
        status: 404
    },
    "406": {
        code: "406.001",
        description: "Request not acceptable",
        status: 406
    },
    "415": {
        code: "415.001",
        description: "Requested payload format is not supported by this method on the target resource. The format problem might be due to the request's indicated Content-Type or Content-Encoding, or as a result of inspecting the data directly.",
        status: 415
    },
    "500": {
        code: "500.001",
        description: "Internal Server Error",
        status: 500
    }
};
