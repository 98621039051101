import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatModal } from "blocks-react/bedrock/components/Modal/Modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CaasRoute } from "../../routes";
import useGlobalStore from "../../store/useGlobalStore";
import CustomerSearch from "./CustomerSearch";
const CustomerSearchModal = () => {
    const [partyNumber, setPartyNumber] = useState("");
    const { setCustomerName, setIsLoggedInAsCAT, setUserPartyNumber } = useGlobalStore((state) => ({
        setCustomerName: state.setCustomerName,
        setIsLoggedInAsCAT: state.setIsLoggedInAsCAT,
        setUserPartyNumber: state.setUserPartyNumber
    }));
    const navigate = useNavigate();
    const dataLayer = (window["dataLayer"] = window["dataLayer"] || []);
    function extractPartyNumberFromSelectedCustomer(partyNumber) {
        const match = partyNumber.match(/\d+/);
        return match ? match[0] : partyNumber;
    }
    const handleModalCancel = () => {
        setUserPartyNumber("");
        navigate(CaasRoute.Home);
    };
    const showProjectsForCustomer = (event) => {
        event.preventDefault();
        setCustomerName(partyNumber);
        setIsLoggedInAsCAT(true);
        setUserPartyNumber(extractPartyNumberFromSelectedCustomer(partyNumber));
        dataLayer.push({
            event: "formSubmitted",
            formAdditionalContent: null,
            formContent: partyNumber,
            formContent2: "select projects form",
            formFieldCausingError: null,
            formLocation: "Home Page",
            formName: "select projects",
            formStatus: "success",
            pagePath: CaasRoute.Home
        });
        navigate(CaasRoute.Home);
    };
    return (_jsx("div", { "data-test": "customer-selection-modal", children: _jsx("form", { id: "select projects", name: "select projects form", onSubmit: (event) => {
                return showProjectsForCustomer(event);
            }, children: _jsxs(CatModal, { disableBackdropClick: true, disableEscClick: true, fullWidth: false, isActive: true, onBlModalCancel: handleModalCancel, size: "lg", children: [_jsx(CatHeading, { "data-test": "customer-selection-header", level: "h4", slot: "header", children: "Select or search a customer from the list below" }), _jsx(CustomerSearch, { partyNumber: partyNumber, setPartyNumber: setPartyNumber, size: "lg" }), _jsx("div", { className: "flex flex-col items-center", "data-test": "customer-selection-footer", slot: "footer", children: _jsx(CatButton, { "data-test": "customer-selection-button", type: "submit", variant: "primary", children: "View Projects" }) })] }) }) }));
};
export default CustomerSearchModal;
