/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Activity refers to current activity performed by a Machine.
 *
 */
export var Activity;
(function (Activity) {
    Activity["CHARGING"] = "Charging";
    Activity["DISCHARGING"] = "Discharging";
    Activity["IDLE"] = "Idle";
    Activity["NO_ACTIVITY_LOGGED"] = "NoActivityLogged";
    Activity["TRANSPORTING"] = "Transporting";
})(Activity || (Activity = {}));
