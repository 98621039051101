import { useQuery } from "@tanstack/react-query";
import { refetchApiInterval } from "../../shared/constants";
import { getEntitlementsData } from "../api/entitlements-service";
export const useEntitlementsQuery = () => {
    return useQuery({
        gcTime: 0,
        queryFn: () => {
            return getEntitlementsData();
        },
        queryKey: ["entitlements"],
        refetchInterval: refetchApiInterval,
        retry: 2
    });
};
