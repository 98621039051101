/* eslint-disable perfectionist/sort-enums */
/**
 * HTTP error status codes
 * Represents an error code, description, and detailed error message for a given endpoint.
 * Every HTTP status code returns a different `code` value in the response based on the nature of the error that occurred.
 * Also, one HTTP status code can return different `code` for different use cases.
 * The below enum contains  mapping of different `Code`, 'HTTP Error Message'
 * and `Description` for each HTTP status code.
 */
export var HttpErrorCode;
(function (HttpErrorCode) {
    /**
     *  Bad Response (400.001-400.010)
     *
     */
    /**
     * 1.400.001-Unknown error occurred.
     */
    HttpErrorCode[HttpErrorCode["UNKNOWN_ERROR"] = 400.001] = "UNKNOWN_ERROR";
    /**
     * 2.400.1-Filters Must not be empty.
     */
    HttpErrorCode[HttpErrorCode["MISSING_REQFIELDS"] = 400.1] = "MISSING_REQFIELDS";
    /**
     * 3. 400.002- Threat detected: maximum allowed limit of request is exceeded.
     *
     */
    HttpErrorCode[HttpErrorCode["THREAT_DETCTED"] = 400.002] = "THREAT_DETCTED";
    /**
     * 4. 400.006-  Missing required field(s).
     *
     */
    HttpErrorCode[HttpErrorCode["MISSING_REQUIRED"] = 400.006] = "MISSING_REQUIRED";
    /**
     * 5. 400.008-  Invalid or missing request body.
     *
     */
    HttpErrorCode[HttpErrorCode["INVALID_REQBODY"] = 400.008] = "INVALID_REQBODY";
    /**
     * 6. 400.009-  Body not allowed.
     *
     */
    HttpErrorCode[HttpErrorCode["BODY_NOTALLOWED"] = 400.009] = "BODY_NOTALLOWED";
    /**
     * 7. 400.010- XML schema is not valid.
     *
     */
    HttpErrorCode[HttpErrorCode["INVALID_SCHEMA"] = 400.01] = "INVALID_SCHEMA";
    /**
     * 8. 400.102-Invalid value 'new' for field 'filters' .
     *
     */
    HttpErrorCode[HttpErrorCode["INVALID_REQUEST_BODY"] = 400.102] = "INVALID_REQUEST_BODY";
    /**
     *  Unauthorized (401.001-401.008)
     *
     */
    /**
     * 1. 401.001- Invalid access token.
     *
     */
    HttpErrorCode[HttpErrorCode["INVALID_ACCESS"] = 401.001] = "INVALID_ACCESS";
    /**
     * 2. 401.002- Access token expired.
     *
     */
    HttpErrorCode[HttpErrorCode["TOKEN_EXPIRED"] = 401.002] = "TOKEN_EXPIRED";
    /**
     * 3. 401.003- API product mismatch for token.
     *
     */
    HttpErrorCode[HttpErrorCode["PRODUCT_MISMATCH"] = 401.003] = "PRODUCT_MISMATCH";
    /**
     * 4. 401.004- Insufficient scope for application.
     *
     */
    HttpErrorCode[HttpErrorCode["SCOPE_INSUFFICIENT"] = 401.004] = "SCOPE_INSUFFICIENT";
    /**
     * 5. 401.005- Invalid API key for given resource.
     *
     */
    HttpErrorCode[HttpErrorCode["INVALID_API"] = 401.005] = "INVALID_API";
    /**
     * 6. 401.006- Missing access token.
     *
     */
    HttpErrorCode[HttpErrorCode["MISSING_TOKEN"] = 401.006] = "MISSING_TOKEN";
    /**
     * 7. 401.007- Invalid or expired access token.
     *
     */
    HttpErrorCode[HttpErrorCode["EXPIRED_TOKEN"] = 401.007] = "EXPIRED_TOKEN";
    /**
     * 8. 401.008- User not authorized.
     *
     */
    HttpErrorCode[HttpErrorCode["UNAUTHORIZED_USER"] = 401.008] = "UNAUTHORIZED_USER";
    /**
     *  Forbidden (403.001-403.003)
     *
     */
    /**
     * 1. 403.001- Request not coming from valid IP.
     *
     */
    HttpErrorCode[HttpErrorCode["INVALID_REQUESTIP"] = 403.001] = "INVALID_REQUESTIP";
    /**
     * 2. 403.002- There was an error getting entitlements.
     *
     */
    HttpErrorCode[HttpErrorCode["ENTITLEMENT_ERROR"] = 403.002] = "ENTITLEMENT_ERROR";
    /**
     * 3. 403.003- ClientId: <ClientId> does not have permission for: <http method> <ResourceURL>.
     *
     */
    HttpErrorCode[HttpErrorCode["CLIENTID_NOTPERMISSION"] = 403.003] = "CLIENTID_NOTPERMISSION";
    /**
     *  Not Found (404.001)
     *
     */
    /**
     * 1. 404.001- Resource not found
     *
     */
    HttpErrorCode[HttpErrorCode["RESOURCE_NOTFOUND"] = 404.001] = "RESOURCE_NOTFOUND";
    /**
     *  Method Not Allowed  (405.001)
     *
     */
    /**
     * 1. 405.001- Method Not Allowed
     *
     */
    HttpErrorCode[HttpErrorCode["METHOD_NOT_ALLOWED"] = 405.001] = "METHOD_NOT_ALLOWED";
    /**
     *  Not Acceptable (406.001)
     *
     */
    /**
     * 1. 406.001- Request not acceptable. The target resource does not have a current representation that would be acceptable to the user agent.
     *
     */
    HttpErrorCode[HttpErrorCode["REQUEST_NOT_ACCEPTABLE"] = 406.001] = "REQUEST_NOT_ACCEPTABLE";
    /**
     *  Conflict (409.001)
     *
     */
    /**
     * 1. 409.001- Request conflicts with current stated
     *
     */
    HttpErrorCode[HttpErrorCode["REQUEST_CONFLICT"] = 409.001] = "REQUEST_CONFLICT";
    /**
     *  Payload Too Large (413.001)
     *
     */
    /**
     * 2. 413.001- Payload Too Large
     *
     */
    HttpErrorCode[HttpErrorCode["LARGE_PAYLOAD"] = 413.001] = "LARGE_PAYLOAD";
    /**
     *  Unsupported Media Type (415.001)
     *
     */
    /**
     * 3. 415.001- Unsupported media type. Requested payload format is not supported by this method on the target resource. The format problem might be due to the request's indicated content-type or content-encoding, or as a result of inspecting the data directly.
     *
     */
    HttpErrorCode[HttpErrorCode["UNSUPPORTED_MEDIA"] = 415.001] = "UNSUPPORTED_MEDIA";
    /**
     * Too Many Requests (429.001-429.002)
     *
     */
    /**
     * 4. 429.001- Throttling rate limit exceeded.
     *
     */
    HttpErrorCode[HttpErrorCode["THROTTLING_LIMIT_EXCEED"] = 429.001] = "THROTTLING_LIMIT_EXCEED";
    /**
     * 5. 429.002- Quota rate limit exceeded.
     *
     */
    HttpErrorCode[HttpErrorCode["QUOTA_LIMIT_EXCEED"] = 429.002] = "QUOTA_LIMIT_EXCEED";
    /**
     * Internal Server Error (500.001-500.006)
     *
     */
    /**
     * 6. 500.001- Server error.
     *
     */
    HttpErrorCode[HttpErrorCode["SERVER_ERROR"] = 500.001] = "SERVER_ERROR";
    /**
     * 7. 500.002- Shared flow not found.
     *
     */
    HttpErrorCode[HttpErrorCode["SHARED_FLOW_NOTFOUND"] = 500.002] = "SHARED_FLOW_NOTFOUND";
    /**
     * 8. 500.003-  Script execution failed.
     *
     */
    HttpErrorCode[HttpErrorCode["SCRIPT_EXECUTION_FAILED"] = 500.003] = "SCRIPT_EXECUTION_FAILED";
    /**
     * 9. 500.004- Error occurred while getting response from backend. Please try later.
     *
     */
    HttpErrorCode[HttpErrorCode["BACKEND_ERROR"] = 500.004] = "BACKEND_ERROR";
    /**
     * 10. 500.005- Missing mandatory configuration properties.
     *
     */
    HttpErrorCode[HttpErrorCode["MISSING_MANDATORY_CONFIG"] = 500.005] = "MISSING_MANDATORY_CONFIG";
    /**
     * 11. 500.006- Unable to AssumeRole for authentication.
     *
     */
    HttpErrorCode[HttpErrorCode["FAILED_AUTHENTICATION_ASSUMEROLE"] = 500.006] = "FAILED_AUTHENTICATION_ASSUMEROLE";
    /**
     * Service Unavailable (503.001)
     *
     */
    /**
     * 12. 503.001- The service is temporarily unavailable.
     *
     */
    HttpErrorCode[HttpErrorCode["SERVICE_UNAVAILABLE"] = 503.001] = "SERVICE_UNAVAILABLE";
    /**
     * Gateway Timeout (504.001)
     *
     */
    /**
     * 13. 504.001- Request timed out.
     *
     */
    HttpErrorCode[HttpErrorCode["REQUEST_TIMEOUT"] = 504.001] = "REQUEST_TIMEOUT";
})(HttpErrorCode || (HttpErrorCode = {}));
