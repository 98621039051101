export var Point;
(function (Point) {
    /**
     * Type of GeoJson geometry, example Point, MultiPoint.
     */
    let type;
    (function (type) {
        type["POINT"] = "Point";
    })(type = Point.type || (Point.type = {}));
})(Point || (Point = {}));
