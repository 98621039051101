import { create } from "zustand";
import { logger } from "./logger";
const initialState = {
    customerName: "",
    globalNotification: {
        showNotification: false
    },
    isAgreementAccepted: false,
    isButtonActive: false,
    isLoading: false,
    isLoggedInAsCAT: false,
    isUsageHistoryLoading: false,
    userEntitlements: {},
    userPartyNumber: ""
};
const useGlobalStore = create()(logger((set) => ({
    ...initialState,
    setCustomerName: (customerName) => {
        set(() => ({ customerName }));
    },
    setGlobalNotification: (globalNotification) => {
        set(() => ({ globalNotification }));
    },
    setIsAgreementAccepted: (isAgreementAccepted) => {
        set(() => ({ isAgreementAccepted }));
    },
    setIsButtonActive: (isButtonActive) => {
        set(() => ({ isButtonActive }));
    },
    setIsLoading: (isLoading) => {
        set(() => ({ isLoading }));
    },
    setIsLoggedInAsCAT: (isLoggedInAsCAT) => {
        set(() => ({ isLoggedInAsCAT }));
    },
    setIsUsageHistoryLoading: (isUsageHistoryLoading) => {
        set(() => ({ isUsageHistoryLoading }));
    },
    setUserEntitlements: (userEntitlements) => {
        set(() => ({ userEntitlements }));
    },
    setUserPartyNumber: (userPartyNumber) => {
        set(() => ({ userPartyNumber }));
    }
}), "globalStore"));
export default useGlobalStore;
