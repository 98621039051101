/* eslint-disable @typescript-eslint/no-explicit-any */
import { appConfigApi } from "../../lib/api";
export const getAppConfigData = async () => {
    let data;
    await appConfigApi
        .get(`/appConfig.json`)
        .then((res) => {
        data = res.data;
    })
        .catch((err) => console.log(err));
    return data;
};
