import { Response } from "miragejs";
import { AssetTransitDirection } from "../../../../../../pages/Projects/ProjectDetails.helper";
import { HttpStatusCode } from "../../../../../utils/src/lib/constants/StatusCodes";
export function assetNetworkResponse() {
    return new Response(HttpStatusCode.OK, {}, {
        assetNetworkDetails: [
            {
                activity: "Charging",
                assetId: "bbfe281c-dbd2-4939-b048-bf421d9666389",
                assetName: "533-330",
                assetSerialNumber: "XNB20241",
                assetSourceType: "Machine",
                chargingSource: null,
                estimatedRemainingChargeTime: 0,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-07T10:25:30.000000000",
                    position: {
                        coordinates: ["41.88", "-87.71"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 84,
                timestamp: "2024-06-07T10:21:30"
            },
            {
                activity: "Charging",
                assetId: "bbfe281c-dbd2-4939-b048-bf421d96663e",
                assetName: "433-330",
                assetSerialNumber: "XNB20241",
                assetSourceType: "Machine",
                chargingSource: null,
                estimatedRemainingChargeTime: 0,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-07T10:25:30.000000000",
                    position: {
                        coordinates: ["41.88", "-87.71"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 84,
                timestamp: "2024-06-07T10:21:30"
            },
            {
                activity: "Charging",
                assetId: "bbfe281c-dbd2-4939-b048-bf421d966632",
                assetName: "233-330",
                assetSerialNumber: "XNB20241",
                assetSourceType: "Machine",
                chargingSource: "AM7",
                estimatedRemainingChargeTime: 0,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-07T10:25:30.000000000",
                    position: {
                        coordinates: ["41.88", "-87.71"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 84,
                timestamp: "2024-06-07T10:21:30"
            },
            {
                activity: "Charging",
                assetId: "bbfe281c-dbd2-4939-b048-bf421d966631",
                assetName: "133-330",
                assetSerialNumber: "XNB20241",
                assetSourceType: "Machine",
                chargingSource: "BQ5",
                estimatedRemainingChargeTime: 0,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-07T10:25:30.000000000",
                    position: {
                        coordinates: ["41.88", "-87.71"].map(Number),
                        type: "points"
                    },
                    streetAddress: "300 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 84,
                timestamp: "2024-06-07T10:21:30"
            },
            {
                activity: "Charging",
                assetId: "185dc18e-f465-45f9-8018-c75677ea1add",
                assetName: "RL3-Rental",
                assetSerialNumber: null,
                assetSourceType: "ESS",
                chargingSource: "WattHub",
                estimatedRemainingChargeTime: 0,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["31.89558793590709", "-67.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 67,
                timestamp: "2024-06-13T15:40"
            },
            {
                activity: "Charging",
                assetId: "185dc18e-f465-45f9-8018-c75677ea1ad0",
                assetName: "RL1-Rental",
                assetSerialNumber: null,
                assetSourceType: "ESS",
                chargingSource: "WattHub",
                estimatedRemainingChargeTime: 0,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["31.89558793590709", "-67.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 67,
                timestamp: "2024-06-13T15:40"
            },
            {
                activity: "Charging",
                assetId: "185dc18e-f465-45f9-8018-c75677ea1ad",
                assetName: "RL11-Rental",
                assetSerialNumber: null,
                assetSourceType: "ESS",
                chargingSource: "WattHub",
                estimatedRemainingChargeTime: 0,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["31.89558793590709", "-67.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 67,
                timestamp: "2024-06-13T15:40"
            },
            {
                activity: "Transporting",
                assetId: "BQ3",
                assetName: "BQ6-380",
                assetSerialNumber: "sg23-ams-13-32dcckw-ac2u",
                assetSourceType: "ESS",
                assetTransitDirection: AssetTransitDirection.TOWARDS_SOURCE,
                chargingSource: null,
                estimatedRemainingChargeTime: 33,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["41.89558793590709", "-27.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 87,
                timestamp: "2024-03-24T23:59:59.999Z"
            },
            {
                activity: "Transporting",
                assetId: "BQ7",
                assetName: "BQ7-380",
                assetSerialNumber: "sg23-ams-13-32dcckw-ac2u",
                assetSourceType: "ESS",
                assetTransitDirection: AssetTransitDirection.TOWARDS_SITE,
                chargingSource: null,
                estimatedRemainingChargeTime: 33,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["41.89558793590709", "-27.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 87,
                timestamp: "2024-03-24T23:59:59.999Z"
            },
            {
                activity: "Discharging",
                assetId: "BQ5",
                assetName: "BQ5-380",
                assetSerialNumber: "sg23-ams-13-32dcckw-ac2u",
                assetSourceType: "ESS",
                assetTransitDirection: AssetTransitDirection.AT_SITE,
                chargingSource: null,
                estimatedRemainingChargeTime: 33,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["41.89558793590709", "-27.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 87,
                timestamp: "2024-03-24T23:59:59.999Z"
            },
            {
                activity: "Discharging",
                assetId: "AM6",
                assetName: "AM6-380",
                assetSerialNumber: "sg23-ams-13-32dcckw-ac2u",
                assetSourceType: "ESS",
                assetTransitDirection: AssetTransitDirection.AT_SITE,
                chargingSource: null,
                estimatedRemainingChargeTime: 33,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["41.89558793590709", "-27.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 87,
                timestamp: "2024-03-24T23:59:59.999Z"
            },
            {
                activity: "Idle",
                assetId: "AM7",
                assetName: "AM7-380",
                assetSerialNumber: "sg23-ams-13-32dcckw-ac2u",
                assetSourceType: "ESS",
                assetTransitDirection: AssetTransitDirection.AT_SITE,
                chargingSource: null,
                estimatedRemainingChargeTime: 33,
                lifetimeRunningHours: 0,
                location: {
                    lastReportedTime: "2024-06-13 15:40:00",
                    position: {
                        coordinates: ["41.89558793590709", "-27.716320132078"].map(Number),
                        type: "points"
                    },
                    streetAddress: "800 N Central Park Ave, Chicago, IL 60624"
                },
                odometerReading: 0,
                soc: 87,
                timestamp: "2024-03-24T23:59:59.999Z"
            }
        ],
        projectId: "ZYY12345",
        projectName: "Mekante Diek"
    });
}
