import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatModal } from "blocks-react/bedrock/components/Modal/Modal";
import { CatTextPassage } from "blocks-react/bedrock/components/TextPassage/TextPassage";
import { useNavigate } from "react-router-dom";
import { CaasRoute } from "../../routes";
import { useCreateProjectMutate } from "../../services/query/create-project-api.query";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import { Variant } from "../GlobalNotification/GlobalNotification";
const NewProjectPendingChangesModel = (props) => {
    const { onModalClose, showModal } = props;
    const navigate = useNavigate();
    const { projectAddress, projectName, projectOwner } = useCreateNewProjectStore((state) => ({
        projectAddress: state.projectAddress,
        projectName: state.projectName,
        projectOwner: state.projectOwner
    }));
    const { setGlobalNotification, userPartyNumber } = useGlobalStore((state) => ({
        setGlobalNotification: state.setGlobalNotification,
        userPartyNumber: state.userPartyNumber
    }));
    const { mutate } = useCreateProjectMutate(userPartyNumber);
    const createProjectRequest = {
        owner: projectOwner,
        projectAddress,
        projectName
    };
    const onSubmitmodal = (formData) => {
        mutate(formData, {
            onError: () => {
                setGlobalNotification({
                    message: `Creating new project ${projectName} has failed.Please try later`,
                    showNotification: true,
                    type: Variant.error
                });
                navigate(CaasRoute.Home);
            },
            onSuccess: () => {
                setGlobalNotification({
                    message: `New project ${projectName} created in pending state`,
                    showNotification: true,
                    type: Variant.success
                });
                navigate(CaasRoute.Home);
            }
        });
    };
    return (_jsxs(CatModal, { disableBackdropClick: true, heading: "Save for later", headingVariant: "title", isActive: showModal, onBlModalClose: () => {
            onModalClose(false);
        }, size: "sm", children: [_jsx(CatTextPassage, { size: "sm", children: _jsxs("div", { children: [_jsx(CatHeading, { level: "h3", children: `Save ${projectName} for later` }), _jsx("p", { className: "mt-5", children: "This will save your project in the pending tab of the Projects Home page so you can complete it later." })] }) }), _jsxs("div", { className: "flex gap-3", slot: "footer", children: [_jsx(CatButton, { onClick: () => {
                            onSubmitmodal(createProjectRequest);
                            onModalClose(false);
                        }, variant: "primary", children: "Save" }), _jsx(CatButton, { onClick: () => {
                            onModalClose(false);
                            navigate(CaasRoute.Home);
                        }, variant: "primary", children: "Discard" }), _jsx(CatButton, { onClick: () => {
                            onModalClose(false);
                        }, variant: "ghost", children: "Cancel" })] })] }));
};
export default NewProjectPendingChangesModel;
