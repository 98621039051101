/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Project Status represents the current state of the project. User can query using Status as ACTIVE OR CLOSED.
 *
 */
export var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["ACTIVE"] = "active";
    ProjectStatus["CLOSED"] = "closed";
})(ProjectStatus || (ProjectStatus = {}));
