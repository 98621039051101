/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Asset Transit Direction of asset travel or location if its not moving.
 */
export var AssetTransitStatus;
(function (AssetTransitStatus) {
    AssetTransitStatus["AT_SITE"] = "AtSite";
    AssetTransitStatus["AT_SOURCE"] = "AtSource";
    AssetTransitStatus["TOWARDS_SOURCE"] = "TowardsSource";
    AssetTransitStatus["TOWARDS_SITE"] = "TowardsSite";
})(AssetTransitStatus || (AssetTransitStatus = {}));
