/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Enum that defines all possible filter types.
 */
export var StringFilterType;
(function (StringFilterType) {
    StringFilterType["STRING_EQUALS"] = "stringEquals";
})(StringFilterType || (StringFilterType = {}));
