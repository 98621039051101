/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Project Properties that are used for filtering.
 *
 */
export var ProjectPropertiesForFiltering;
(function (ProjectPropertiesForFiltering) {
    ProjectPropertiesForFiltering["PROJECT_ID"] = "projectId";
    ProjectPropertiesForFiltering["PROJECT_NAME"] = "projectName";
})(ProjectPropertiesForFiltering || (ProjectPropertiesForFiltering = {}));
