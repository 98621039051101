export const usageHistoryHistogramDailyData = {
    energyHistogram: {
        daily: [
            {
                date: "2024-04-30",
                value: 193.2
            },
            {
                date: "2024-04-29",
                value: 192.4
            },
            {
                date: "2024-04-28",
                value: 182.1
            },
            {
                date: "2024-04-27",
                value: 153.9
            },
            {
                date: "2024-04-26",
                value: 203.7
            },
            {
                date: "2024-04-25",
                value: 193.5
            },
            {
                date: "2024-04-24",
                value: 121.3
            }
        ]
    },
    projectId: "b51cfaa2-5755-4234-8548-f415a3d63a11",
    projectName: "Tiwa"
};
export const usageHistoryHistogramWeeklyData = {
    energyHistogram: {
        weekly: [
            {
                endDate: "2024-04-28",
                startDate: "2024-04-22",
                value: 2494.2063
            },
            {
                endDate: "2024-05-05",
                startDate: "2024-04-29",
                value: 1920.7457
            },
            {
                endDate: "2024-05-12",
                startDate: "2024-05-06",
                value: 1603.8514
            },
            {
                endDate: "2024-05-19",
                startDate: "2024-05-13",
                value: 2780.9421
            },
            {
                endDate: "2024-05-26",
                startDate: "2024-05-20",
                value: 1933.2982
            },
            {
                endDate: "2024-06-02",
                startDate: "2024-05-27",
                value: 8.509131
            }
        ]
    },
    projectId: "b51cfaa2-5755-4234-8548-f415a3d63a11",
    projectName: "Tiwa"
};
export const usageHistoryHistogramMonthlyData = {
    energyHistogram: {
        monthly: [
            {
                endDate: "2024-03-31",
                startDate: "2024-03-01",
                value: 142190.98
            },
            {
                endDate: "2024-04-30",
                startDate: "2024-04-01",
                value: 54389.316
            },
            {
                endDate: "2024-05-31",
                startDate: "2024-05-01",
                value: 8436.655
            },
            {
                endDate: "2024-06-30",
                startDate: "2024-06-01",
                value: 1.7212402
            }
        ]
    },
    projectId: "b51cfaa2-5755-4234-8548-f415a3d63a11",
    projectName: "Tiwa"
};
export const usageHistoryESSHourlyData = {
    assetSourceType: "ESS",
    energyHistogram: {
        hourly: [
            {
                date: "2024-07-29T00:00:00",
                value: 193.2
            },
            {
                date: "2024-07-29T00:01:00",
                value: 192.4
            },
            {
                date: "2024-07-29T00:02:00",
                value: 182.1
            },
            {
                date: "2024-07-29T00:03:00",
                value: 153.9
            },
            {
                date: "2024-07-29T00:04:00",
                value: 203.7
            },
            {
                date: "2024-07-29T00:05:00",
                value: 193.5
            },
            {
                date: "2024-07-29T00:06:00",
                value: 121.3
            },
            {
                date: "2024-07-29T00:07:00",
                value: 193.2
            },
            {
                date: "2024-07-29T00:08:00",
                value: 192.4
            },
            {
                date: "2024-07-29T00:09:00",
                value: 182.1
            },
            {
                date: "2024-07-29T00:10:00",
                value: 153.9
            },
            {
                date: "2024-07-29T00:11:00",
                value: 203.7
            },
            {
                date: "2024-07-29T00:12:00",
                value: 193.5
            }
        ]
    },
    projectId: "2wdss23-sdc212-al2cs2vd",
    projectName: "Tiwa"
};
export const usageHistoryMachineHourlyData = {
    assetSourceType: "MACHINE",
    energyHistogram: {
        hourly: [
            {
                date: "2024-07-29T00:00:00",
                value: 0
            },
            {
                date: "2024-07-29T00:01:00",
                value: 0
            },
            {
                date: "2024-07-29T00:02:00",
                value: 0
            },
            {
                date: "2024-07-29T00:03:00",
                value: 0
            },
            {
                date: "2024-07-29T00:04:00",
                value: 0
            },
            {
                date: "2024-07-29T00:05:00",
                value: 0
            },
            {
                date: "2024-07-29T00:06:00",
                value: 50.3
            },
            {
                date: "2024-07-29T00:07:00",
                value: 100.2
            },
            {
                date: "2024-07-29T00:08:00",
                value: 150.4
            },
            {
                date: "2024-07-29T00:09:00",
                value: 180.1
            },
            {
                date: "2024-07-29T00:10:00",
                value: 225.9
            },
            {
                date: "2024-07-29T00:11:00",
                value: 240.7
            },
            {
                date: "2024-07-29T00:12:00",
                value: 250.5
            },
            {
                date: "2024-07-29T00:13:00",
                value: 300.4
            },
            {
                date: "2024-07-29T00:14:00",
                value: 320.1
            },
            {
                date: "2024-07-29T00:15:00",
                value: 330.9
            },
            {
                date: "2024-07-29T00:16:00",
                value: 350.7
            },
            {
                date: "2024-07-29T00:17:00",
                value: 350.5
            }
        ]
    },
    projectId: "2wdss23-sdc212-al2cs2vd",
    projectName: "Tiwa"
};
