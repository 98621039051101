/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Aggregation represents the grouping of data for the selected date,
 * consisting of enum string daily or weekly or monthly.
 *
 */
export var Aggregation;
(function (Aggregation) {
    Aggregation["DAILY"] = "daily";
    Aggregation["WEEKLY"] = "weekly";
    Aggregation["MONTHLY"] = "monthly";
})(Aggregation || (Aggregation = {}));
