/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * AssetId that is used for filtering.
 *
 */
export var AssetIdPropertyForFiltering;
(function (AssetIdPropertyForFiltering) {
    AssetIdPropertyForFiltering["ASSET_ID"] = "assetId";
})(AssetIdPropertyForFiltering || (AssetIdPropertyForFiltering = {}));
