import { Response, createServer } from "miragejs";
import { baseUrls, baseUrlsList, endpointsList } from "../api.types";
import { HttpStatusCode } from "../utils/src/lib/constants/StatusCodes";
import { MockRouteMethods } from "./src/lib/constants";
import { assetNetworkResponse } from "./src/lib/project-details/__mocks__/assetsNetwork.mock";
import { assetsListResponse } from "./src/lib/project-details/__mocks__/projectDetails.mock";
import { appConfigHandler, energyActivityHandler, eventSearchHandler, usageHistoryHistogramHandler } from "./src/lib/project-details/factories/projectDetails";
const MOCK_SERVER_URL_LOCAL = "http://localhost:3000";
export const initializeMockServer = (apiConfigs) => {
    const DEFAULT_ROUTE_TIMING = 400;
    createServer({
        routes() {
            // Iterate the routes and assign the request handlers
            apiConfigs?.forEach((route) => {
                route.methods.forEach((method) => {
                    try {
                        // Read list of baseURLs to mock or define the default MOCK_SERVER_URL_LOCAL
                        const baseURLs = route.baseURLs?.length ? route.baseURLs : [MOCK_SERVER_URL_LOCAL];
                        baseURLs.forEach((baseUrl) => {
                            // if timing key is defined read that or default value
                            this[method.verb](`${baseUrl}${route.path}`, method.handler, {
                                timing: route.timing ?? DEFAULT_ROUTE_TIMING
                            });
                        });
                    }
                    catch {
                        throw new Error(`Unsupported method or path. Method: ${method.verb}, path: ${route.path}`);
                    }
                });
            });
            baseUrlsList.forEach((baseUrl) => {
                if (baseUrl.alwaysPassThrough) {
                    this.passthrough(`${baseUrl.url}/*`);
                    return;
                }
                const filteredEndpoints = endpointsList.filter((endpoint) => endpoint.baseUrl === baseUrl);
                filteredEndpoints.forEach((filteredEndpoint) => {
                    if (apiConfigs.some((apiConfig) => apiConfig.baseURLs[0] !== baseUrl.url || apiConfig.path !== filteredEndpoint.path)) {
                        this.passthrough(`${baseUrl}${filteredEndpoint.path}`);
                    }
                });
            });
        }
    });
};
function projectSearchResponse() {
    return new Response(HttpStatusCode.OK, {}, {
        projects: [
            {
                assetCount: 1,
                customerName: "Customer 1",
                energyTrend: -12.899977,
                essCount: [
                    {
                        assetMake: "PSW",
                        count: 1
                    },
                    {
                        assetMake: "BAB",
                        count: 1
                    }
                ],
                owner: "John",
                pastWeekEnergyConsumed: 1739.419,
                projectId: "308a84bb-fc3a-442e-92c9-5508086385f0",
                projectName: "Site 1",
                projectStatus: "ACTIVE",
                startDate: "2023-08-01",
                streetAddress: "200 Legacy Blvd, Greenwood, IN 46143"
            },
            {
                assetCount: 1,
                customerName: "Customer 2",
                energyTrend: 11.853612,
                essCount: [
                    {
                        assetMake: "PSW",
                        count: 1
                    },
                    {
                        assetMake: "BAB",
                        count: 1
                    }
                ],
                owner: "Dona",
                pastWeekEnergyConsumed: 12479.438,
                projectId: "b51cfaa2-5755-4234-8548-f415a3d63a11",
                projectName: "Site 2",
                projectStatus: "ACTIVE",
                startDate: "2023-08-01",
                streetAddress: "300 N Central Park Ave, Chicago, IL 60624"
            }
        ],
        responseMetadata: {}
    });
}
export const apiConfigs = [
    {
        baseURLs: [baseUrls.caasV1ApiDev.url],
        methods: [
            {
                handler: projectSearchResponse,
                verb: MockRouteMethods.POST
            }
        ],
        path: "/projects/search"
    },
    {
        baseURLs: [baseUrls.caasV1ApiDev.url],
        methods: [
            {
                handler: assetsListResponse,
                verb: MockRouteMethods.POST
            }
        ],
        path: "/projects/:projectId/assets/search"
    },
    {
        baseURLs: [baseUrls.caasV1ApiDev.url],
        methods: [
            {
                handler: assetNetworkResponse,
                verb: MockRouteMethods.GET
            }
        ],
        path: "/projects/:projectId/network/connections"
    },
    {
        baseURLs: [baseUrls.caasV1ApiDev.url],
        methods: [
            {
                handler: usageHistoryHistogramHandler,
                verb: MockRouteMethods.GET
            }
        ],
        path: "/projects/:projectId/energy/histogram"
    },
    {
        baseURLs: [baseUrls.caasV1ApiDev.url],
        methods: [
            {
                handler: energyActivityHandler,
                verb: MockRouteMethods.GET
            }
        ],
        path: "/projects/:projectId/energy/activities"
    },
    {
        baseURLs: [baseUrls.caasV1ApiDev.url],
        methods: [
            {
                handler: eventSearchHandler,
                verb: MockRouteMethods.POST
            }
        ],
        path: "/projects/:projectId/assets/events/search"
    },
    {
        baseURLs: ["https://localhost:3000"],
        methods: [
            {
                handler: appConfigHandler,
                verb: MockRouteMethods.GET
            }
        ],
        path: "/appConfig.json"
    }
];
